import React, { PureComponent, createContext, Context } from 'react';
import { Subscription } from 'rxjs';
import { throttleTime } from 'rxjs/operators';
import { LegacyGraphHoverEvent, LegacyGraphHoverClearEvent, PanelProps } from '@grafana/data';
import { SimpleOptions } from '../types';
import log from 'loglevel';

const PanelContext: Context<any> = createContext(null);

interface Props extends PanelProps<SimpleOptions> {}

interface State {
  frameUrl: string;
  requestedUrl: string;
  hoverTimestamp: string;
}

interface UPlot {
  valToPos: (value: number, scale: string) => number;
  posToIdx: (pos: number) => number;
  setCursor: (opts: { left: number; top: number }) => void;
  data: Array<Array<{ y: number }>>;
}

interface HoverEvent {
  payload: {
    point: {
      time: number;
    };
  };
}

log.setLevel('debug');

export class MyVideoScrubber extends PureComponent<Props, State> {
  static contextType = PanelContext;
  private plotInstance: React.RefObject<HTMLDivElement>;
  private subscription: Subscription = new Subscription();
  private panelContext: any;

  constructor(props: Props) {
    super(props);
    this.plotInstance = React.createRef();
    this.state = {
      frameUrl: '',
      requestedUrl: '',
      hoverTimestamp: '',
    };
  }

  componentDidMount() {
    this.handleFetchFrame(new Date().toISOString());
    this.setupEventSubscriptions();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevState.hoverTimestamp !== this.state.hoverTimestamp) {
      this.handleFetchFrame(this.state.hoverTimestamp);
    }
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  setupEventSubscriptions() {
    // Add your event subscriptions here
    // Example:
    // this.subscription.add(...)
  }

  async handleFetchFrame(timestamp: string) {
    try {
      // Adjust timestamp for timezone offset
      const adjustedTimestamp = new Date(timestamp);
      adjustedTimestamp.setHours(adjustedTimestamp.getHours() - 12);
  
      const frameUrl = `${this.props.options.videoFeedUrl}${adjustedTimestamp.toISOString()}`;
      const response = await fetch(frameUrl);
      if (!response.ok) {
        throw new Error('Failed to fetch frame');
      }
      this.setState({ frameUrl });
    } catch (error) {
      console.error('Error fetching frame:', error);
      this.setState({ frameUrl: '' });
    }
  }

  render() {
    const { frameUrl, hoverTimestamp } = this.state;
  
    return (
      <div style={{ overflow: 'hidden', margin: 0, width: '100%', height: '100%', position: 'relative' }}>
        <p style={{ position: 'absolute', top: 0, left: 0, zIndex: 1 }}>Hover Timestamp: {hoverTimestamp}</p>
        <div style={{ position: 'relative', width: '100%', height: '100%' }}>
          {frameUrl ? (
            <img
              src={frameUrl}
              alt="Video Frame"
              style={{ width: '100%', height: '100%', objectFit: 'contain' }}
            />
          ) : (
            <p style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 1 }}>No frame available</p>
          )}
        </div>
        <div ref={this.plotInstance}></div>
      </div>
    );
  }
  
}
