import { PanelPlugin } from '@grafana/data';
import { SimpleOptions } from './types';
import { MyVideoScrubber } from './components/Panel';
import { SimpleEditor } from './components/SimpleEditor';

export const plugin = new PanelPlugin<SimpleOptions>(MyVideoScrubber).setPanelOptions((builder) => {
  return builder
    .addTextInput({
      path: 'videoFeedUrl',
      name: 'Video Feed URL',
      description: 'URL for the video feed',
      defaultValue: 'https://nuc:5000/frame?timestamp=', // Default value for video feed URL
    })
    .addTextInput({
      path: 'imageSize',
      name: 'Image Size',
      description: 'Size of the displayed image',
      defaultValue: '200px', // Default value for image size
    })
    .addNumberInput({
      path: 'scaleX',
      name: 'Scale X',
      description: 'Scaling factor for the X axis',
      defaultValue: 1, // Default value for scale X
    })
    .addNumberInput({
      path: 'scaleY',
      name: 'Scale Y',
      description: 'Scaling factor for the Y axis',
      defaultValue: 1, // Default value for scale Y
    });
});
